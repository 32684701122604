import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const ANewLookForYourWatch: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/lon-new-look-header.jpg)' }}
        ></div>
        <article className="post">
          <h1>A new look for your watch</h1>
          <p>
            Longines will add the possibility to personalize a Longines watch
            with a brand-new online product: the straps. We will allow our
            personalize it, up to the buckle color. These products can be
            purchased on our corporate e-stores either with a watch or
            separately in a single order.
          </p>
          <p>Here are some of the future designs:</p>
          <h3>Global straps section containing:</h3>
          <ul>
            <li>Filters,</li>
            <li>Variation module to discover other colors on mouseover,</li>
            <li>Search by watch or collection fitment,</li>
            <li>
              Call to actions directly on the PR pictures along the page to land
              directly on the strap we saw on a picture,
            </li>
          </ul>
          <LightboxWrapper>
            <a href={'../../img/lon-new-look.jpg'}>
              <img
                src={'../../img/lon-new-look.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <div className="spacer"></div>
          <hr />
          <div className="spacer"></div>
          <h3>Product page with:</h3>
          <ul>
            <li>Choice of size,</li>
            <li>Color variation module,</li>
            <li>Coming in 2° semester: choice of the length (XS or XL)</li>
          </ul>
          <LightboxWrapper>
            <a href={'../../img/lon-new-look2.jpg'}>
              <img src={'../../img/lon-new-look2.jpg'} className="thumbnail" />
            </a>
          </LightboxWrapper>
          <div className="spacer"></div>
          <p>Customization of your buckle</p>
          <div className="d-flex">
            <LightboxWrapper>
              <a href={'../../img/lon-new-look3.jpg'}>
                <img
                  src={'../../img/lon-new-look3.jpg'}
                  className="w-50 thumbnail"
                />
              </a>
              <a href={'../../img/lon-new-look4.jpg'}>
                <img
                  src={'../../img/lon-new-look4.jpg'}
                  className="w-50 thumbnail"
                />
              </a>
            </LightboxWrapper>
          </div>
          <div className="spacer"></div>
          <hr />
          <div className="spacer"></div>
          <h3>On a watch product page:</h3>
          <ul>
            <li>
              A new module that allows the clients to directly simulate the
              strap on the watch head.
            </li>
          </ul>
          <LightboxWrapper>
            <a href={'../../img/lon-new-look5.jpg'}>
              <img
                src={'../../img/lon-new-look5.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <div className="spacer"></div>
          <p>
            Once you add a watch on the cart, again we will push a notification
            to the client to remind him that he can directly add an additional
            strap to his cart,
          </p>
          <LightboxWrapper>
            <a href={'../../img/lon-new-look6.jpg'}>
              <img
                src={'../../img/lon-new-look6.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <div className="spacer"></div>
          <p>
            If he misses all the above opportunities and goes to the checkout,
            we will push again a strap module that will give the possibility to
            add a strap without leaving the checkout process:
          </p>
          <LightboxWrapper>
            <a href={'../../img/lon-new-look7.jpg'}>
              <img src={'../../img/lon-new-look7.jpg'} className="img-fluid" />
            </a>
          </LightboxWrapper>

          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default ANewLookForYourWatch

export const pageQuery = graphql`
  query ANewLookForYourWatchQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
